<template>
  <b-modal
    id="academy-banner"
    centered
    hide-header
    hide-footer
    @hidden="onHidden"
  >
  <div>
    <div class="image-wrapper">
      <img :src="require('@/assets/images/academy-dash.png')" class="image"/>
    </div>
    <div class="content-wrapper">
      <div class="new">
        <Academy />
        NOVO
      </div>
      <div class="title">Conheça o Eyecare Academy</div>
      <div class="description">
        Com essa nova ferramenta você terá acesso a cursos e aulas  exclusivas, disponíveis para assistir!
        <br/><br/>
        Clique em “saiba mais” para conhecer.
      </div>
    </div>
    <div class="actions-wrapper">
      <b-button
        variant="primary-outline"
        @click="$bvModal.hide('academy-banner')"
      >
        Voltar
      </b-button>
      <b-button
        variant="primary"
        @click="goToAcademy"
      >
        Saiba mais
      </b-button>
    </div>
  </div>
  </b-modal>
</template>
<script>
import Academy from '@/assets/icons/academy.svg'

export default {
  components: { Academy },
  mounted() {
    // this.showModal() && this.$bvModal.show('academy-banner')
  },
  methods: {
    goToAcademy() {
      this.setLocalStorageFlag()
      this.$router.push('/academy')
    },
    onHidden() {
      this.setLocalStorageFlag()
    },
    setLocalStorageFlag() {
      localStorage.setItem('academy-banner', true);
    },
    showModal() {
      return !localStorage.getItem('academy-banner')
    }
  }
}
</script>
<style lang="scss">
#academy-banner {
  .modal-content {
    width: 480px;
    border-radius: 8px;
    .modal-body {
      padding: 0;
      .image-wrapper {
        height: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--blue-100);
        border-radius: 8px 8px 0 0;
        .image {
          margin-top: 24px;
          width: 100%;
        }
      }
      .content-wrapper {
        padding: 24px;
        .new {
          display: inline-flex;
          align-items: center;
          gap: 5px;
          padding: 4px 8px;
          color: white;
          border-radius: 8px;
          font-weight: 800;
          font-size: 14px;
          background: var(--orange);

          svg {
            fill: white;
          }
        }
        .title {
          margin-top: 24px;
        }
        .description {
          font-weight: 400;
          font-size: 16px;
        }
      }
      .actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 24px 24px 24px;
        gap: 5px;
      }
    }
  }
}
</style>
